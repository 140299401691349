<template>
  <div id="smart-banner" v-if="visible" class="main-container">
    <button class="close-button" @click="closeSmartBanner">
      <img class="close-icon" src="/images/icon-close.png">
    </button>

    <img class="play-store-logo" src="/images/HT-logo-store.png">

    <div class="app-info-container">
      <p class="primary-text">{{ appName }}</p>
      <div class="review-container">
        <img v-for="star in stars" :key="star" class="icon-star" src="/images/icon-star.png">
        <p class="review-text">{{ reseñas }}</p>
      </div>

      <p class="secondary-text">{{ developerName }}</p>
      <p class="secondary-text">{{ subInfo }}</p>
    </div>
    <a class="open-button" :href="appLink">Abrir</a>
  </div>
</template>

<script>
import { butter } from '@/buttercms';

export default {
  name: 'AndroidSmartBanner',
  props: {
    appId: {
      type: String,
    },
  },
  data() {
    return {
      appName: "",
      developerName: "",
      subInfo: "",
      stars: 0,
      reviews: "",
      appLink: "https://play.google.com/store/apps/details?id=" + this.appId,
      visible: true,
    };
  },
  created() {
    this.fetchCms();
  },
  methods: {
    fetchCms() {
      butter.content
        .retrieve(['google_play_store_info'], {
          preview: 1,
          locale: "en",
        })
        .then(resp => {
          const { app_name, developer_name, sub_info, stars, reviews } = resp.data.data.google_play_store_info[0];
          this.appName = app_name;
          this.developerName = developer_name;
          this.subInfo = sub_info;
          this.stars = stars;
          this.reviews = reviews.toLocaleString();
        })
        .catch(function(resp) {
          console.log(resp);
        });
    },
    closeSmartBanner() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped="" lang="scss">
  .main-container {
  display: flex;
  position: relative;
	width: 100%;
	line-height: 5rem;
	background-color: #e4e4e4;
  align-items: center;
  justify-content: center;
  padding: 5px 20px;
  z-index: 9998;
}

.close-button {
  border: none;
  background-color: #e4e4e4;
  cursor: pointer;
}

.close-icon {
  width: 1rem;
  height: 1rem;
  opacity: 65%;
  margin-right: auto;
}

.app-info-container {
  display: flex;
  flex-direction: column;
  margin: 0 0.5rem;
}

.play-store-logo {
  width: 4.5rem;;
  height: 4.5rem;
  padding: 5px;
}

.primary-text {
  font-size: 1.0rem;
  font-weight: bold;
  margin: 0;
}

.secondary-text {
  font-size: 0.8rem;
  height: 1.1rem;
  margin: 0;
}

.review-text {
  font-size: 0.6rem;
  color: #818080ea;
  margin: 0 5px;
  padding-top: 2px;
}

.review-container {
  display: flex;
  align-items: center;
  margin: 0 0 5px 0;
  max-height: 0.625rem;
}

.icon-star {
  max-height: 0.625rem;
  margin: 0 2px;
}

.open-button {
  background-color: #e4e4e4;
  color: #1d65ff;
  font-size: 0.9rem;
  margin-left: auto;
}

@media (max-width: 26.25rem) {
  .main-container {
    padding: 5px 10px;
  }

  .primary-text {
    font-size: 0.8rem;
  }

  .secondary-text {
    font-size: 0.7rem;
  }

  .review-text {
    font-size: 0.5rem;
  }
}
</style>
