<template>
  <div class="nav-item-container" :style="cssVars">
    <div class="icon-container">
      <img v-if="isProductItem" :src="headerIcon(item.params.slug)" :alt="item.params.slug" class="slug-icon">
      <img v-else="" :src="headerIcon(navIconName)" :alt="navIconName" class="slug-icon">
    </div>
    <div class="label-container">
      <a v-if="item.is_external_link" :href="item.destination" @click="clicked">
        {{ item.label }}
      </a>
      <router-link v-else="" :to="getNavPath(item)" @click.native="clicked">
        {{ item.label }}
        <p v-if="item.description" class="nav-item-description">
          {{ item.description }}
        </p>
      </router-link>
      <span v-if="item.is_new" class="new">{{ $t('navegaciónNueva') }}</span>
    </div>
  </div>
</template>

<script>
import { PRODUCT_PAGE_ICON_FOLDER } from '@/constants/cloudinary.js';
import getPath from '@/navpaths.js';

export default {
  name: 'NavigationItem',
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    clicked: {
      type: Function,
    },
  },
  data() {
    return {
      isProductItem: this.item.params && this.item.params.slug,
      navIconName: '',
    };
  },
  created() {
    //Get nav icon name when item.destination has slashes
    if (this.item.destination) {
      const arr = this.item.destination.split('/');
      this.navIconName = arr[arr.length - 1];
    }
  },
  computed: {
    cssVars() {
      return {
        '--itemsAlignment': this.isProductItem ? 'unset' : 'center',
        '--iconWidthHeight': this.isProductItem ? '2.3rem' : '2rem',
        '--slugIconMaxWidthHeight': this.isProductItem
          ? '1.225rem'
          : '1.825rem',
      };
    },
  },
  methods: {
    isCurrentLocation(navItem) {
      const locale = this.$route.params.locale;

      if (navItem.is_external_link) {
        return false;
      }

      // remove leading slashes before comparing
      const uriFromCms = navItem.destination.startsWith('/')
        ? `${locale}${navItem.destination}`
        : `${locale}/${navItem.destination}`;
      const curPath = this.$route.path.startsWith('/')
        ? this.$route.path.slice(1)
        : this.$route.path;

      return uriFromCms === curPath;
    },
    getNavPath(navItem) {
      if (navItem.name) {
        return navItem;
      }
      return getPath(navItem);
    },
    headerIcon(icon) {
      return `${PRODUCT_PAGE_ICON_FOLDER}/icon-${icon}.png`;
    },
  },
};
</script>

<style scoped="" lang="scss">
.nav-item-container a {
  font-size: 0.938rem;
  line-height: 1.225rem;

  @include bp-mediumsmall {
    font-size: 1.125rem;
    line-height: 1.4625rem;
  }

  @include bp-medium {
    font-size: 0.938rem;
    line-height: 1.225rem;
  }
}
.nav-item-description {
  font-size: 0.813rem;
  line-height: 1.225rem;

  @include bp-mediumsmall {
    font-size: 1.125rem;
    line-height: 1.4625rem;
  }

  @include bp-medium {
    font-size: 0.813rem;
    line-height: 1.225rem;
  }
}

.nav-item-container {
  display: flex;
  align-items: var(--itemsAlignment);
  .icon-container {
    width: var(--iconWidthHeight);
    height: var(--iconWidthHeight);
    border-radius: 0.457rem;
    background: #f5f5f6;
    margin-right: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    .slug-icon {
      max-height: var(--slugIconMaxWidthHeight);
      max-width: var(--slugIconMaxWidthHeight);
    }
  }
  a {
    display: block;
    position: relative;
    text-decoration: none;
    color: $gray-700;
    font-weight: 700;

    &.action-link {
      color: #0073ff;
    }

    @include bp-medium {
      &:not(:last-child) {
        display: block;
        margin-right: 1.5rem;
      }
    }

    @include bp-mediumlarge {
      display: block;
    }
  }
}
.nav-item-description {
  margin-top: 0.25rem;
  color: $gray-500;
  font-weight: 400;
}
</style>
