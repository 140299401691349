<template>
  <div class="indicator">{{`Ver: ${latestVersion} - SHA: ${latestCommit}`}}</div>
</template>

<script>
export default {
  props: {
    latestVersion: {
      type: String,
      default: '',
    },
    latestCommit: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped="" lang="scss">
  .indicator {
    position: fixed;
    bottom: 0;
    left: 0;
    background: red;
    padding: 0.3rem 2.5rem;
    display: table;
    font-size: 0.6875rem;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
    transition: opacity 0.5s linear;
    z-index: 1000;
    &:hover {
      opacity: 0;
    }
  }
</style>
