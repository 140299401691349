<template>
  <div class="stores" v-if="showComponent">
    <a @click="onClick" :href="urls.APP_STORE" target="_blank" class="wrapper">
      <img :src="appStoreImage" width="160" height="48" alt="Descargar Healthy Together en el App Store" class="store">
    </a>
    <a @click="onClick" :href="urls.PLAY_STORE" target="_blank" class="wrapper">
      <img :src="playStoreImage" width="160" height="48" alt="Descargar Healthy Together en el App Store" class="store">
    </a>
  </div>
</template>

<script>
import cloudinary from '@/constants/cloudinary';
import urls from '@/constants/urls';

export default {
  props: {
    isOnDarkBackground: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    appStoreImage() {
      return this.isOnDarkBackground
        ? cloudinary.APP_STORE_LIGHT
        : cloudinary.APP_STORE_DARK;
    },
    playStoreImage() {
      return this.isOnDarkBackground
        ? cloudinary.PLAY_STORE_LIGHT
        : cloudinary.PLAY_STORE_DARK;
    },
    showComponent() {
      return this.$route.query.mobile_app !== 'true';
    },
  },
  created() {
    this.urls = urls;
    this.cloudinary = cloudinary;
  },
  methods: {
    onClick() {
      this.$emit('nav');
    },
  },
};
</script>

<style lang="scss" scoped="">
.wrapper {
  height: 3rem;
}

.stores {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
}

.store {
  width: 8rem;
}
</style>
